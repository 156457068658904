<template>
  <div class="hold-transition">
    <div class="modal fade" id="modal_contables">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Asignar Cecos/Afes</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalContable"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center" v-if="asignacion">
              <div class="col-md-8">
                <div class="card card-widget">
                  <div class="card-footer text-muted">
                    <div class="row justify-content-center">
                      <div class="col-sm-4 border-right">
                        <div class="description-block">
                          <h5 class="description-header">
                            {{ asignacion.nombre }}
                          </h5>
                          <span class="description-text">IMPUTACIÓN</span>
                        </div>
                      </div>
                      <div class="col-sm-4 border-right">
                        <div class="description-block">
                          <h5 class="description-header">
                            {{ asignacion.bloque.nombre }}
                          </h5>
                          <span class="description-text">BLOQUE</span>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="description-block">
                          <h5
                            class="description-header"
                            :class="
                              asignacion.estado == 1
                                ? 'text-success'
                                : asignacion.estado == 2
                                ? 'text-danger'
                                : 'text-secundary'
                            "
                          >
                            {{ asignacion.nEstado }}
                          </h5>
                          <span class="description-text">ESTADO</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <h5
                  class="text-center"
                  v-if="csContables.length > 0"
                  :class="
                    totalPresupuesto == 100 ? 'text-success' : 'text-danger'
                  "
                >
                  Porcentaje Total {{ totalPresupuesto }}%
                </h5>
              </div>
              <div class="col-md-1">
                <button
                  v-if="totalPresupuesto < 100"
                  type="button"
                  class="btn btn-sm bg-primary float-right"
                  @click="addContable()"
                  style="cursor: pointer"
                  :disabled="estado_contable != -1"
                >
                  <i class="fas fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
          <div
            class="modal-body pt-0"
            v-if="csContables.length > 0 || estado_contable == 1"
          >
            <table
              class="table table-bordered table-striped table-hover table-sm text-xs mb-0"
            >
              <thead class="bg-dark text-center">
                <tr class="text-nowarp">
                  <th>Acciones</th>
                  <th>Area</th>
                  <th>OPEX/CAPEX</th>
                  <th>CECO/AFE/G&A</th>
                  <th>
                    {{
                      csContable.tipo_cargo_obj != null
                        ? csContable.tipo_cargo_obj.numeracion == 1
                          ? "Dueño AFE"
                          : csContable.tipo_cargo_obj.numeracion == 2
                          ? "Responsable CECO"
                          : "Responsable/Dueño"
                        : "Responsable/Dueño"
                    }}
                  </th>
                  <th>Activo Producción</th>
                  <th style="width: 8em">%</th>
                </tr>
              </thead>
              <tbody id="tbody">
                <tr v-for="(contable, index) in csContables" :key="contable.id">
                  <td class="text-center p-1">
                    <div class="btn-group" v-if="estado_contable != 2">
                      <button
                        type="button"
                        class="btn bg-navy"
                        :disabled="
                          estado_contable != -1 || csContable.estado == 2
                        "
                        @click="editContable(index)"
                      >
                        <i class="fas fa-edit"></i>
                      </button>
                      <button
                        class="btn bg-danger"
                        :disabled="
                          estado_contable != -1 || csContable.estado == 2
                        "
                        @click="destroy(index, contable)"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                    <div
                      class="btn-group"
                      v-if="
                        estado_contable == 2 && id_actualizar_contable == index
                      "
                    >
                      <button
                        type="button"
                        class="btn bg-success"
                        v-if="!$v.csContable.$invalid"
                        @click="saveUpdateContable(index)"
                      >
                        <i class="fas fa-save"></i>
                      </button>
                      <button class="btn bg-danger" @click="cancelContable()">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                    <div
                      class="btn-group"
                      v-if="
                        estado_contable == 2 && id_actualizar_contable !== index
                      "
                    >
                      <button type="button" class="btn bg-navy" disabled>
                        <i class="fas fa-edit"></i>
                      </button>
                      <button class="btn bg-danger" disabled>
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </td>
                  <td class="text-center">
                    <div
                      v-if="
                        id_actualizar_contable == index && estado_contable == 2
                      "
                    >
                      <select
                        id="area"
                        class="form-control form-control-sm col-md-12"
                        v-model="csContable.area_obj"
                        :class="
                          $v.csContable.area_obj.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="area in listasForms.areas"
                          :key="area.numeracion"
                          :value="area"
                        >
                          {{ area.descripcion }}
                        </option>
                      </select>
                    </div>
                    <div v-else>
                      <div v-if="contable.area_obj !== undefined">
                        {{ contable.area_obj.descripcion }}
                      </div>
                      <div v-else>
                        {{ contable.nArea }}
                      </div>
                    </div>
                  </td>
                  <td class="text-center p-1">
                    <div
                      v-if="
                        id_actualizar_contable == index && estado_contable == 2
                      "
                    >
                      <select
                        id="tipo_cargo"
                        class="form-control form-control-sm col-md-12"
                        v-model="csContable.tipo_cargo_obj"
                        :class="
                          $v.csContable.tipo_cargo_obj.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @change="getTipoCargo()"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="opex in listasForms.opex_capex"
                          :key="opex.numeracion"
                          :value="opex"
                        >
                          {{ opex.descripcion }}
                        </option>
                      </select>
                    </div>
                    <div v-else>
                      <div
                        v-if="
                          contable.tipo_cargo_obj !== undefined &&
                          contable.tipo_cargo_obj !== null
                        "
                      >
                        {{ contable.tipo_cargo_obj.descripcion }}
                      </div>
                      <div v-else>
                        {{ contable.opexCapex }}
                      </div>
                    </div>
                  </td>
                  <td class="p-1">
                    <div
                      class="row"
                      v-if="
                        id_actualizar_contable == index && estado_contable == 2
                      "
                    >
                      <div class="col-md-5" v-if="csContable.tipo_cargo_obj">
                        <select
                          v-model="csContable.tipo_presupuesto"
                          placeholder="Ceco AFE"
                          label="nombres"
                          class="form-control form-control-sm p-0"
                          :class="
                            $v.csContable.tipo_presupuesto.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          @change="asignarCecosAfes()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="lista in listasForms.lista_cecos_afes"
                            :key="lista.numeracion"
                            :value="lista.numeracion"
                          >
                            {{ lista.descripcion }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-7">
                        <v-select
                          v-model="csContable.cecosAfes_obj"
                          :class="[
                            $v.csContable.cecosAfes_obj.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          class="form-control form-control-sm p-0"
                          style="font-size: 0.8em"
                          v-if="csContable.tipo_presupuesto"
                          placeholder="CeCo / AFE"
                          label="codigo_contable"
                          :options="listasForms.cecos_afes"
                          @input="asignarResponsable()"
                        ></v-select>
                      </div>
                    </div>
                    <div v-else>
                      <div class="row">
                        <div class="col-md-4 text-center">
                          <span
                            class="badge"
                            :class="
                              contable.tipo_presupuesto == 1
                                ? 'bg-info'
                                : 'bg-navy'
                            "
                          >
                            {{ contable.tipo_cargo_obj.cod_alterno }}
                          </span>
                        </div>
                        <div
                          class="col-md-8"
                          v-if="
                            contable.cecosAfes_obj !== undefined &&
                            contable.cecosAfes_obj !== null
                          "
                        >
                          <input
                            :value="contable.cecosAfes_obj.codigo_contable"
                            type="text"
                            class="form-control form-control-sm"
                            style="font-size: 0.85em"
                            disabled
                          />
                        </div>
                        <div class="col-md-8" v-else>
                          <input
                            :value="contable.ceco_afe.codigo_contable"
                            type="text"
                            class="form-control form-control-sm"
                            style="font-size: 0.85em"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="p-1">
                    <div
                      v-if="
                        id_actualizar_contable == index && estado_contable == 2
                      "
                    >
                      <v-select
                        v-model="csContable.responsable_obj"
                        :class="[
                          $v.csContable.responsable_obj.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        placeholder="Responsable"
                        label="funcionario"
                        class="form-control form-control-sm p-0"
                        :options="listasForms.responsables"
                        :filterable="true"
                      ></v-select>
                    </div>
                    <div class="row" v-else>
                      <div
                        class="col-md-12"
                        v-if="
                          contable.responsable_obj !== undefined &&
                          contable.responsable_obj !== null
                        "
                      >
                        <input
                          :value="contable.responsable_obj.funcionario"
                          type="text"
                          class="form-control form-control-sm"
                          style="font-size: 0.85em"
                          disabled
                        />
                      </div>
                      <div class="col-md-8" v-else>
                        <input
                          :value="contable.responsable.name"
                          type="text"
                          class="form-control form-control-sm"
                          style="font-size: 0.85em"
                          disabled
                        />
                      </div>
                    </div>
                  </td>
                  <td class="text-center">
                    <div
                      v-if="
                        id_actualizar_contable == index && estado_contable == 2
                      "
                    >
                      <select
                        id="activo_produccion"
                        class="form-control form-control-sm col-md-12"
                        v-model="csContable.activo_produccion_obj"
                        :class="
                          $v.csContable.activo_produccion_obj.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="activoP in listasForms.activos_produccion"
                          :key="activoP.numeracion"
                          :value="activoP"
                        >
                          {{ activoP.descripcion }}
                        </option>
                      </select>
                    </div>
                    <div v-else>
                      <div
                        v-if="
                          contable.activo_produccion_obj !== undefined &&
                          contable.activo_produccion_obj !== null
                        "
                      >
                        {{ contable.activo_produccion_obj.descripcion }}
                      </div>
                      <div v-else>
                        {{ contable.activoProduccion }}
                      </div>
                    </div>
                  </td>
                  <td class="text-center p-1">
                    <div
                      v-if="
                        id_actualizar_contable == index && estado_contable == 2
                      "
                    >
                      <input
                        v-model="csContable.porcentaje"
                        type="number"
                        class="form-control form-control-sm"
                        id="porcentaje"
                        :class="
                          $v.csContable.porcentaje.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </div>
                    <div v-else>
                      {{ contable.porcentaje }}
                    </div>
                  </td>
                </tr>
                <tr v-if="estado_contable == 1">
                  <td class="text-center">
                    <div class="btn-group">
                      <button
                        class="btn bg-success"
                        v-if="!$v.csContable.$invalid"
                        @click="saveContable()"
                      >
                        <i class="fas fa-save"></i>
                      </button>
                      <button class="btn bg-danger" @click="cancelContable()">
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </td>
                  <td>
                    <select
                      id="tipo_cargo"
                      class="form-control form-control-sm col-md-12"
                      v-model="csContable.area_obj"
                      :class="
                        $v.csContable.area_obj.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="opex in listasForms.areas"
                        :key="opex.numeracion"
                        :value="opex"
                      >
                        {{ opex.descripcion }}
                      </option>
                    </select>
                    <div
                      v-if="
                        (!$v.csContable.area_obj.$dirty ||
                          $v.csContable.area_obj.$invalid) &&
                        (csContable.area_obj == '' ||
                          csContable.area_obj == null)
                      "
                    >
                      <span class="text-danger"
                        ><small>*Campo requerido</small></span
                      >
                    </div>
                  </td>
                  <td>
                    <select
                      id="tipo_cargo"
                      class="form-control form-control-sm col-md-12"
                      v-model="csContable.tipo_cargo_obj"
                      :class="
                        $v.csContable.tipo_cargo_obj.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @change="
                        [$v.csContable.tipo_cargo_obj.$touch, getTipoCargo()]
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="opex in listasForms.opex_capex"
                        :key="opex.numeracion"
                        :value="opex"
                      >
                        {{ opex.descripcion }}
                      </option>
                    </select>
                    <div
                      v-if="
                        (!$v.csContable.tipo_cargo_obj.$dirty ||
                          $v.csContable.tipo_cargo_obj.$invalid) &&
                        (csContable.tipo_cargo_obj == '' ||
                          csContable.tipo_cargo_obj == null)
                      "
                    >
                      <span class="text-danger"
                        ><small>*Campo requerido</small></span
                      >
                    </div>
                  </td>
                  <td>
                    <div class="row" v-if="csContable.tipo_cargo_obj">
                      <div
                        class="col-md-5"
                        v-if="listasForms.lista_cecos_afes.length > 0"
                      >
                        <select
                          v-model="csContable.tipo_presupuesto"
                          placeholder="Ceco AFE"
                          label="nombres"
                          class="form-control form-control-sm"
                          :class="
                            $v.csContable.tipo_presupuesto.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          @change="
                            [
                              $v.csContable.tipo_presupuesto.$touch,
                              asignarCecosAfes(),
                            ]
                          "
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="lista in listasForms.lista_cecos_afes"
                            :key="lista.numeracion"
                            :value="lista.numeracion"
                          >
                            {{ lista.descripcion }}
                          </option>
                        </select>
                        <div
                          v-if="
                            (!$v.csContable.tipo_presupuesto.$dirty ||
                              $v.csContable.tipo_presupuesto.$invalid) &&
                            (csContable.tipo_presupuesto == '' ||
                              csContable.tipo_presupuesto == null)
                          "
                        >
                          <span class="text-danger"
                            ><small>*Campo requerido</small></span
                          >
                        </div>
                      </div>
                      <div class="col-md-7" v-if="csContable.tipo_presupuesto">
                        <v-select
                          v-model="csContable.cecosAfes_obj"
                          :class="[
                            $v.csContable.cecosAfes_obj.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          class="form-control form-control-sm p-0"
                          v-if="csContable.tipo_presupuesto"
                          @input="
                            [
                              $v.csContable.cecosAfes_obj.$touch,
                              asignarResponsable(),
                            ]
                          "
                          placeholder="CeCo / AFE"
                          label="codigo_contable"
                          :options="listasForms.cecos_afes"
                        ></v-select>
                        <div
                          v-if="
                            (!$v.csContable.cecosAfes_obj.$dirty ||
                              $v.csContable.cecosAfes_obj.$invalid) &&
                            (csContable.cecosAfes_obj == '' ||
                              csContable.cecosAfes_obj == null)
                          "
                        >
                          <span class="text-danger"
                            ><small>*Campo requerido</small></span
                          >
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <v-select
                      :class="[
                        $v.csContable.responsable_obj.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="csContable.responsable_obj"
                      v-on:input="$v.csContable.responsable_obj.$touch"
                      placeholder="Responsable"
                      label="funcionario"
                      class="form-control form-control-sm p-0"
                      :options="listasForms.responsables"
                      :filterable="true"
                    ></v-select>
                    <div
                      v-if="
                        (!$v.csContable.responsable_obj.$dirty ||
                          $v.csContable.responsable_obj.$invalid) &&
                        (csContable.responsable_obj == '' ||
                          csContable.responsable_obj == null)
                      "
                    >
                      <span class="text-danger"
                        ><small>*Campo requerido</small></span
                      >
                    </div>
                  </td>
                  <td>
                    <select
                      id="tipo_cargo"
                      class="form-control form-control-sm col-md-12"
                      v-model="csContable.activo_produccion_obj"
                      :class="
                        $v.csContable.activo_produccion_obj.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="opex in listasForms.activos_produccion"
                        :key="opex.numeracion"
                        :value="opex"
                      >
                        {{ opex.descripcion }}
                      </option>
                    </select>
                    <div
                      v-if="
                        (!$v.csContable.activo_produccion_obj.$dirty ||
                          $v.csContable.activo_produccion_obj.$invalid) &&
                        (csContable.activo_produccion_obj == '' ||
                          csContable.activo_produccion_obj == null)
                      "
                    >
                      <span class="text-danger"
                        ><small>*Campo requerido</small></span
                      >
                    </div>
                  </td>
                  <td>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      id="porcentaje"
                      v-model="csContable.porcentaje"
                      v-on:input="$v.csContable.porcentaje.$touch"
                      :class="
                        $v.csContable.porcentaje.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                    <div
                      v-if="
                        (!$v.csContable.porcentaje.$dirty ||
                          $v.csContable.porcentaje.$invalid) &&
                        (csContable.porcentaje == '' ||
                          csContable.porcentaje == null)
                      "
                    >
                      <span class="text-danger"
                        ><small>*Campo requerido</small></span
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="modal-footer justify-content-between"
            v-if="
              $store.getters.can('cs.asignacionesContables.edit') &&
              totalPresupuesto == 100 &&
              estado_contable == -1
            "
          >
            <button type="button" class="btn btn-primary" @click="save()">
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "CsAsignacionContableCecoAfe",
  components: {
    vSelect,
  },
  data() {
    return {
      csContables: [],
      csContable: {},
      asignacion: null,
      listasForms: {
        areas: [],
        opex_capex: [],
        responsables: [],
        lista_cecos_afes: [],
        cecos_afes: [],
        activos_produccion: [],
      },
      totalPresupuesto: 0,
      estado_contable: -1,
      id_actualizar_contable: -1,
    };
  },

  validations: {
    csContable: {
      tipo_cargo_obj: {
        required,
      },
      area_obj: {
        required,
      },
      tipo_presupuesto: {
        required,
      },
      responsable_obj: {
        required,
      },
      porcentaje: {
        required,
      },
      cecosAfes_obj: {
        required,
      },
      activo_produccion_obj: {
        required,
      },
    },
  },

  methods: {
    getDatosModal(asignacion) {
      this.$parent.cargando = true;
      this.asignacion = asignacion;
      this.csContables = [...asignacion.detalles];

      // Se asignan los valores para el formulario
      this.csContables.forEach((det) => {
        det.responsable_obj = {
          id: det.responsable.id,
          user_id: det.responsable.user_id,
          funcionario: `${det.responsable.nombres} ${det.responsable.apellidos}`,
        };
        det.cecosAfes_obj = {
          id: det.contrato_contable.id,
          codigo_contable: det.contrato_contable.codigo_contable,
          tipo_presupuesto: det.contrato_contable.tipo_presupuesto,
          funcionario_id: det.contrato_contable.funcionario_id,
        };
        det.tipo_cargo_obj = this.listasForms.opex_capex.find(
          (coax) => coax.numeracion == det.tipo_presupuesto
        );
      });
      this.totalPresupuesto = 0;
      (this.estado_contable = -1), this.calcularContable();
      this.$parent.cargando = false;
    },

    /******* Funciones para las listas */
    getOpexCapex() {
      axios.get("/api/lista/132").then((response) => {
        this.listasForms.opex_capex = response.data;
      });
    },

    getResponsables() {
      axios.get("/api/admin/funcionarios/list").then((response) => {
        this.listasForms.responsables = response.data;
      });
    },

    getCecosAfes(numeracion) {
      this.$parent.cargando = true;
      axios
        .get("/api/lista/151", {
          params: {
            in_number: [numeracion],
          },
        })
        .then((response) => {
          this.$parent.cargando = false;
          this.listasForms.lista_cecos_afes = response.data;
        });
    },

    async asignarCecosAfes(tipoP = null) {
      this.listasForms.cecos_afes = [];
      if (tipoP == null) {
        if (this.csContable.tipo_presupuesto == "") {
          this.csContable.tipo_cargo_obj = null;
        }
        tipoP = this.csContable.tipo_presupuesto;
      }
      this.$parent.cargando = true;
      await axios
        .get("/api/funcionariosFrontera/wsObjetosActivos/lista", {
          params: {
            tipo_presupuesto: tipoP,
          },
        })
        .then((response) => {
          this.$parent.cargando = false;
          this.listasForms.cecos_afes = response.data;
        });
    },

    asignarResponsable() {
      this.csContable.responsable_obj = null;
      if (
        this.csContable.cecosAfes_obj &&
        this.csContable.cecosAfes_obj.funcionario
      ) {
        this.csContable.responsable_obj = {
          id: this.csContable.cecosAfes_obj.funcionario.id,
          user_id: this.csContable.cecosAfes_obj.funcionario.user_id,
          funcionario: `${this.csContable.cecosAfes_obj.funcionario.nombres} ${this.csContable.cecosAfes_obj.funcionario.apellidos}`,
        };
      }
    },
    /******* Fin - Funciones para las listas */

    addContable() {
      this.csContable = {
        id: null,
        cs_asignacion_contable_id: this.asignacion.id,
        area: null,
        area_obj: null,
        tipo_cargo: null,
        tipo_cargo_obj: null,
        tipo_presupuesto: null,
        cecosAfes_obj: null,
        ceco_afe_id: null,
        responsable_obj: null,
        responsable_id: null,
        activo_produccion: null,
        activo_produccion_obj: null,
        porcentaje: null,
      };
      this.estado_contable = 1;
    },

    async editContable(index) {
      await this.getCecosAfes(this.csContables[0].tipo_presupuesto);
      this.id_actualizar_contable = index;
      this.estado_contable = 2;
      this.csContable = { ...this.csContables[index] };
      await this.getCecosAfes(this.csContable.tipo_presupuesto);
      await this.asignarCecosAfes();
    },

    async saveContable() {
      let me = this;
      await me.calcularContable();
      if (
        me.totalPresupuesto + parseFloat(me.csContable.porcentaje) <= 100 &&
        me.csContable.porcentaje > 0
      ) {
        me.csContable.area = me.csContable.area_obj.numeracion;
        me.csContable.activo_produccion =
          me.csContable.activo_produccion_obj.numeracion;
        me.csContable.tipo_cargo = me.csContable.tipo_cargo_obj.numeracion;
        if (me.csContable.responsable_obj.user_id !== null) {
          me.csContable.responsable_id = me.csContable.responsable_obj.id;
          me.csContable.ceco_afe_id = me.csContable.cecosAfes_obj.id;

          me.csContables.push(me.csContable);
          me.csContable = {};
          me.estado_contable = -1;
        } else {
          me.$swal({
            icon: "error",
            title:
              "El funcionario seleccionado no está relacionado con algún usuario",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }

        await me.calcularContable();
      } else {
        me.$swal({
          icon: "error",
          title: "Ocurrió un error, el porcentaje no puede ser mayor a 100",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    async saveUpdateContable(index) {
      let me = this;
      if (
        me.totalPresupuesto -
          this.csContables[index].porcentaje +
          parseFloat(me.csContable.porcentaje) <=
          100 &&
        me.csContable.porcentaje > 0
      ) {
        me.csContable.area = me.csContable.area_obj.numeracion;
        me.csContable.activo_produccion =
          me.csContable.activo_produccion_obj.numeracion;
        me.csContable.tipo_cargo = me.csContable.tipo_cargo_obj.numeracion;
        if (me.csContable.responsable_obj.user_id !== null) {
          me.csContable.responsable_id = me.csContable.responsable_obj.id;
          me.csContable.ceco_afe_id = me.csContable.cecosAfes_obj.id;

          this.csContables[index] = this.csContable;
          this.estado_contable = -1;
          this.id_actualizar_contable = -1;
          await me.calcularContable();
        } else {
          me.$swal({
            icon: "error",
            title:
              "El funcionario seleccionado no está relacionado con algún usuario.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } else {
        me.$swal({
          icon: "error",
          title: "Ocurrió un error, el porcentaje no puede ser mayor a 100",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    cancelContable() {
      this.estado_contable = -1;
      this.csContable = {};
    },

    getTipoCargo() {
      this.listasForms.lista_cecos_afes = [];
      this.getCecosAfes(this.csContable.tipo_cargo_obj.numeracion);
      this.csContable.tipo_presupuesto = null;
      this.csContable.cecosAfes_obj = null;
    },

    calcularContable() {
      let totalPresupuesto = 0;
      this.csContables.forEach((element) => {
        totalPresupuesto += parseFloat(element.porcentaje);
      });
      this.totalPresupuesto = totalPresupuesto;
      return totalPresupuesto;
    },

    save() {
      if (this.csContables.length > 0 && this.totalPresupuesto == 100) {
        this.$parent.cargando = true;
        const form = {
          id: this.asignacion.id,
          data: this.csContables,
        };

        axios({
          method: "PUT",
          url: "/api/cs/asignacionesContables/detalles",
          data: form,
        })
          .then(() => {
            this.$parent.cargando = false;
            this.$refs.closeModalContable.click();
            this.$parent.getIndex();

            this.$swal({
              icon: "success",
              title: "La asignación contable se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } else {
        this.$swal({
          icon: "error",
          title: `No es posible realizar la asignación contable con la información diligenciada.`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    async destroy(index, contable = null) {
      await this.$swal({
        title:
          "Está seguro de eliminar esta asignación: " +
          contable.responsable_obj.funcionario +
          "?",
        text:
          "Los cambios no se pueden revertir y recuerde que debe crear una nueva asignación ya que el % de la operacion queda en " +
          (parseFloat(this.totalPresupuesto) -
            parseFloat(contable.porcentaje)) +
          "%",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.$parent.cargando = true;
          this.csContables.splice(index, 1);
          this.addContable();
          this.calcularContable();
          this.$swal({
            icon: "success",
            title: "Se eliminó el CECO/AFE exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        }
      });
    },

    getAreas() {
      axios.get("/api/lista/99").then((response) => {
        this.listasForms.areas = response.data;
      });
    },

    getActivoProduccion() {
      axios.get("/api/lista/137").then((response) => {
        this.listasForms.activos_produccion = response.data;
      });
    },
  },

  beforeMount() {
    this.getAreas();
    this.getOpexCapex();
    this.getResponsables();
    this.getActivoProduccion();
  },
};
</script>
